@import url('https://fonts.googleapis.com/css?family=Titillium+Web');
/* @import url('https://fonts.googleapis.com/css?family=Roboto'); */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
  box-sizing: border-box;
  font-family: 'Titillium Web', "Calibri", sans-serif !important;
  font-size: 0.99em;
  outline:none;
  /* font-family: 'Roboto', sans-serif !important; */
}

