:root {
  --input-padding-x: 1.5rem;
  --input-padding-y: .75rem;
}

@media only screen and (min-width: 751px) {
  .centerLogin {
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.buttonLogin {
  /* background-color: var(--button-background); */
  background-color: var(--buttons-login-background);
  color: var(--buttons-login-font);
  margin-top: 10px;
  border-radius: 10px;
  vertical-align: center;
  text-align: center;
  width: 100%;
  height: 7vh;
  border: none;
  padding: 3px;
  z-index: 30;
}

@media only screen and (max-width: 750px) {
  .centerLogin {
    /* position: absolute; */
    /* float: left; */
    /* left: 50%; */
    margin-top: 50%;
    /* transform: translate(0, -50%); */
  }
}

.card-signin {
  border: 0;
  border-radius: 10px;
  box-shadow: 0.1rem 0.2rem 1rem 0.2rem rgba(0, 0, 0, 0.5);
}

.headerLogin {
  font-weight: bold !important;
  color: var(--filtro-lines) !important;
  font-size: 2em !important;
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;

}

.form-signin {
  width: 100%;
}


.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: .1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
  height: auto;
}

.form-label-group input {
  height: auto;
  border-radius: 10px;
}

.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.logoLogin {
  top: 5vh;
  height: 10vh;
  position: absolute;
  float: left;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 80vw;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: black;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: all .1s ease-in-out;
  margin-top: 5px;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #004136;
}

.buttonLoginText {
  display: inline;
  margin-left: 10px;
}
