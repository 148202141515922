.colorSettings {
	/************************************************** THEMES **************************************************/

	--cor-letra: rgb(0, 0, 0);
	--cor-letra-disable: #000000;
	--background: #e1e6e6;
	--table-borders: #004136;
	--loading: #004136;
	--table-stripped: #919191;
	--table-button-font: #e1e6e6;
	--table-button-background: #01352c;
	--table-options-background: #004136;
	--table-headers-background: #e1e6e6;
	--table-headers-borders: #004136;
	--table-ordermark: #e1e6e6;
	--table-headers-font: #004136;
	--table-lines: #ffffff;
	--filtro-lines: #004136;
	--header-background: #004136;
	--modal-header-background: #e1e6e6;
	--modal-header-font: #004136;
	--navbar-background: rgb(7, 6, 6);
	--navbar-font: #e1e6e6;
	--header-lines: #d4fad6;
	--header-font: #e1e6e6;
	--navBar-font: rgb(148, 148, 148);
	--navBar-font-selected: #e1e6e6;
	--navBar-background: rgb(48, 46, 46);
	--navBar-background-selected: black;
	--navBar-border: rgb(121, 121, 121);
	--navBar-detailed-background: rgb(199, 192, 192);
	--navBar-detailed-font: rgb(48, 46, 46);
	--button-background: #004136;
	--buttons-detail-background: #e1e6e6;
	--buttons-detail-font: #004136;
	--buttons-login-background: #004136;
	--buttons-login-font: #e1e6e6;
	--modal-background: #004136;
	--modal-button-background: #e1e6e6;
	--modal-button-background-hover: #99a1a1;
	--modal-button-font: #004136;
	--modal-font: #e1e6e6;
	--button-font: #e1e6e6;
	--autocomplete-background: white;
	--autocomplete-selected-background: #004136;
	--autocomplete-selected-font: #e1e6e6;
	--autocomplete-font: rgb(0, 0, 0);
	--autocomplete-background-inModal: #e1e6e6;
	--autocomplete-selected-background-inModal: #004136;
	--autocomplete-border: #004136;
	--autocomplete-border-inModal: #e1e6e6;
	--autocomplete-selected-font-inModal: #e1e6e6;
	--autocomplete-font-inModal: rgb(0, 0, 0);

	--lines: 2px;

	/************************************************************************************************************/
}

.ModalShow {
	display: block;
}

.ModalHide {
	display: none;
}

.autocomplete-list {
	border-radius: 5px;
	background-color: var(--autocomplete-background);
	border: none;
	color: var(--autocomplete-font);
	outline: none;
	list-style-type: none;
	padding: 1px;
	height: auto;
	margin-top: 1px;
	font-size: 1em;
	font-weight: 300;
	z-index: 1100;
	width: auto;
}

.autocomplete-toggle {
	border-radius: 5px;
	border: none;
	height: 30px;
	width: 30px;
	text-align: center;
	font-size: 0.7em;
	color: var(--autocomplete-background);
	background: var(--autocomplete-border);
}

.autocomplete-toggle-inModal {
	border-radius: 5px;
	border: none;
	height: 30px;
	width: 30px;
	text-align: center;
	font-size: 0.7em;
	color: var(--autocomplete-font-inModal);
	background: var(--autocomplete-border-inModal);
}

.autocomplete-input {
	float: left;
	width: calc(100% - 30px);
}

.autocomplete-list-inModal {
	border-radius: 5px;
	background-color: var(--autocomplete-background-inModal);
	border: none;
	color: var(--autocomplete-font-inModal);
	outline: none;
	list-style-type: none;
	padding: 1px;
	height: auto;
	margin-top: 1px;
	font-size: 1em;
	font-weight: 300;
	z-index: 1100;
	width: auto;
	/* width: calc(100% - 30px); */
}

.inModal {
	border: none !important;
	background: #ffffff;
}

.autocomplete-text {
	margin-left: 7px;
	margin-top: 7px;
	word-break: break-word;
	text-justify: distribute;
	vertical-align: middle;
}

.autocomplete-text-inModal {
	margin-left: 7px;
	margin-top: 7px;
	word-break: break-word;
	text-justify: distribute;
	vertical-align: middle;
}

.autocomplete {
	user-select: none;
	border-radius: 5px;
	background-color: var(--autocomplete-background);
	border: solid var(--autocomplete-border) var(--lines);
	color: var(--cor-letra);
	height: 150px;
	outline: none;
	/* border: 2px solid var(--cor-2); */
	font-size: 1em;
	padding: 10px;
	font-weight: 300;
	/* margin-top: 5px; */
	z-index: 1100;
	overflow-y: scroll;
	width: 100%;
}

.autocomplete-inModal {
	user-select: none;
	border-radius: 5px;
	background-color: var(--autocomplete-background-inModal);
	border: none;
	color: var(--autocomplete-font-inModal);
	height: 150px;
	outline: none;
	/* border: 2px solid var(--cor-2); */
	font-size: 1em;
	padding: 10px;
	font-weight: 300;
	/* margin-top: 10px; */
	z-index: 1100;
	overflow-y: scroll;
	width: 100%;
}

button {
	transition: 300ms all !important;
}

a {
	transition: 300ms all !important;
}

@media only screen and (min-width: 900px) {
	.buttonHome {
		/*  */
		background-color: transparent;
		border-radius: 10px;
		align-content: center;
		text-align: center;
		width: 5vw;
		height: auto;
		border: none;
		padding: 3px;
		z-index: 30;
	}

	.buttonHomeDiv {
		/* transition: 300ms background; */
		background-color: transparent;
		border-radius: 10px;
		align-content: center;
		text-align: center;
		margin-left: 10px;
		border: none;
		padding: 3px;
		z-index: 30;
	}

	.buttonsHome {
		margin-top: 10px;
		height: auto;
		width: auto;
		text-align: left;
		margin-bottom: 10px;
	}

	.logoHome {
		height: auto;
		margin-left: 5vw;
		display: block;
		width: 90vw;
		position: absolute;
		padding-bottom: 30px;
	}
}

.Modal {
	background-color: rgba(0, 0, 0, 0.5);
	color: rgba(0, 0, 0, 0.5);
	overflow-y: auto;
	/* width: 80%; */
}

.Modal:before {
	content: '';
	display: inline-block;
	vertical-align: top;
	margin-right: -0.25em; /* Adjusts for spacing */
}

.ModalBg {
	background: var(--modal-background);
	color: var(--modal-font);
	border: 1px solid var(--modal-background);
	/* border: 1px red solid; */
}

.inputSwal {
	border: var(--lines) solid var(--filtro-lines);
	border-radius: 5px;
	background: #004136;
	margin-top: 5px;
	padding: 2px 5px 2px 5px;
	margin-left: 10px;
	color: white;
}

.swal-button--cancelar {
	background-color: #e8e8e8 !important;
	color: #555555 !important;
}

.ModalButton {
	background-color: var(--modal-button-background);
	color: var(--modal-button-font);
	font-weight: bold;
	border-radius: 10px;
	text-align: center;
	width: 25%;
	margin-left: 10px;
	border: none;
	padding: 3px;
}

.ModalButtonColumns {
	background-color: var(--modal-button-background);
	color: var(--modal-button-font);
	font-weight: bold;
	border-radius: 10px;
	text-align: center;
	width: 100%;
	border: none;
	padding: 3px;
}

#html_btn {
	display: none;
}

#html_path {
	display: none;
}

#pdf_btn {
	display: none;
}

#sede_btn {
	display: none;
}

#btn_estatuto {
	display: none;
}

#html_btnAssociado {
	display: none;
}



.groupSwal {
	border: var(--lines) solid var(--filtro-lines);
}

.ModalBody {
	/* background-color: var(--cor-fundo); */
	z-index: 1500;
	margin-left: 10%;
	margin-bottom: 10px;
	width: 80%;
	height: auto;
	text-align: center;
}

.ModalHeader {
	/* background-color: var(--cor-fundo); */
	z-index: 1500;
	margin-left: 10%;
	margin-top: 20px;
	width: 80%;
	height: auto;
	text-align: center;
}

.ModalFooter {
	/* background-color: var(--cor-fundo); */
	z-index: 1500;
	margin-left: 10%;
	width: 80%;
	height: auto;
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: right;
}

.buttonsCadastro {
	text-align: right;
}

@media only screen and (max-width: 900px) {
	.buttonHome {
		background-color: transparent;
		border-radius: 10px;
		align-content: center;
		text-align: center;
		width: auto;
		width: auto;
		height: 10vh;
		margin-left: 10px;
		border: none;
		padding: 3px;
		z-index: 30;
	}

	.buttonHomeDiv {
		/* transition: 300ms all; */
		background-color: transparent;
		border-radius: 10px;
		align-content: center;
		text-align: center;
		margin-left: 10px;
		border: none;
		padding: 3px;
		z-index: 30;
	}

	.buttonsHome {
		margin-top: 10px;
		height: auto;
		text-align: left;
		margin-bottom: 10px;
	}

	.logoHome {
		height: auto;
		padding-top: 50vh;
		margin-left: 5vw;
		display: block;
		width: 90vw;
		padding-bottom: 30px;
	}
}

.iconApergs {
	width: 20px;
	height: 20px;
	margin-right: 5px;
	fill: white;
}

.boxSite {
	height: 100vh;
	-webkit-touch-callout: none;
	margin: 0;
	width: auto;
	background: var(--background);
	/* border: 1px red solid; */
	font-family: 'Titillium Web', sans-serif;
	color: var(--cor-letra);
	/* display: content; */
}

.swal-button--cancel {
	background-color: #004136;
	color: #e8e8e8;
}

.swal-button--cancel:hover {
	background-color: #004136 !important;
	color: #e8e8e8 !important;
}

.swal-button--confirm {
	background-color:  #969292 !important;
	color: #e8e8e8 !important;
}

.swal-button--confirm:hover {
	background-color:  #969292 !important;
	color: #e8e8e8 !important;
}



.boxTela {
	height: calc(100vh - 52px);
	margin: 0;
	overflow-y: auto;
	background: var(--background);
	font-family: 'Titillium Web', sans-serif;
	color: var(--cor-letra);
}

.boxFiltros {
	/* height: 30vh; */
	margin-left: 30px;
	margin-right: 30px;
	display: block;

	/* border: 1px dashed black; */
}

.filtros {
	border-radius: 10px;
	border: var(--lines) solid var(--filtro-lines);
	padding: 5px;
}

.boxRelatorio {
	border-radius: 10px;
	border: var(--lines) solid var(--filtro-lines);
	padding: 5px;
	background: var(--modal-background);
	color: var(--modal-font);
	margin-left: 30px;
	margin-right: 30px;
	display: block;
	width: calc(100% - 60px);
	height: auto;
}

.relatorio {
	width: calc(100% - 20px);
	margin: 10px;
	border: none;
	height: 600px;
	border-radius: 10px;
}

.bordered {
	border-radius: 10px;
	border: var(--lines) solid var(--filtro-lines);
	padding-bottom: 10px;
	padding-top: 10px;
	margin-top: 10px;
	width: calc(100% - 30px);
	margin-left: 15px;
}

.filtros:after {
	content: "";
	display: table;
	clear: both;
}

.itemFiltro {
	display: inline-block;
	margin-left: 5px;
	margin-top: 0px;
	margin-bottom: 5px;
	margin-right: 5px;
	font-size: 0.95em;
	vertical-align: top;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.inputFiltro {
	padding: 3px;
	margin-left: 0px;
	margin-right: 5px;
	height: 30px;
	vertical-align: middle;
	line-height: 200%;
	margin-top: 0px;
	border-radius: 5px;
	border: none;
	color: var(--cor-letra);
	background: white;
	outline: none;
	border: var(--lines) solid var(--filtro-lines);
	font-size: 1em;
	padding: 2px;
}

.selectFiltro {
	margin-left: 0px;
	height: 30px;
	vertical-align: middle;
	line-height: 200%;
	border-radius: 5px;
	border: none;
	color: var(--cor-letra);
	background: white;
	outline: none;
	border: var(--lines) solid var(--filtro-lines);
	font-size: 1em;
	padding: 2px;
}

.selectModal {
	margin-left: 0px;
	height: 30px;
	/* vertical-align: middle; */
	line-height: 200%;
	border-radius: 5px;
	border: none;
	color: var(--cor-letra);
	background: white;
	outline: none;
	border: var(--lines) solid var(--filtro-lines);
	font-size: 1em;
	padding: 2px;
	margin-top: 0px;
}

.selectFiltro:disabled {
	margin-left: 0px;
	height: 30px;
	vertical-align: middle;
	line-height: 200%;
	border-radius: 5px;
	border: none;
	color: var(--cor-letra);
	background: #eeeeee;
	outline: none;
	border: var(--lines) solid var(--filtro-lines);
	font-size: 1em;
	padding: 2px;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
	background-color: var(--filtro-lines);
	color: var(--modal-font);
	border-radius: 5px 5px 0 0;
	border-top: 1px solid var(--filtro-lines);
	border-left: 1px solid var(--filtro-lines);
	border-right: 1px solid var(--filtro-lines);
}

.boxRegistro {
	border: 2px solid var(--filtro-lines);
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 10px;
	padding-bottom: 30px;
	border-radius: 10px;
}

.nav-tabs > li > a:hover {
	background-color: var(--filtro-lines);
	color: var(--modal-font);
	border-radius: 5px 5px 0 0;
	border-top: 1px solid var(--filtro-lines);
	border-left: 1px solid var(--filtro-lines);
	border-right: 1px solid var(--filtro-lines);
	border-bottom: 1px solid var(--filtro-lines);
}

.nav-tabs > li > a {
	background-color: rgba(12, 92, 43, 0.479);
	color: var(--modal-font);
	border-radius: 5px 5px 0 0;
	border-top: 1px solid var(--filtro-lines);
	border-left: 1px solid var(--filtro-lines);
	border-right: 1px solid var(--filtro-lines);
}

.labelFiltro {
	display: block;
	font-size: .9em;
	color: var(--cor-letra);
}

.tab-pane {
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	border-radius: 0 10px 10px 10px;
	border: var(--lines) solid var(--filtro-lines);
}

.form-control {
	padding: 3px;
	margin-left: 0px;
	height: 30px;
	line-height: 200%;
	margin-top: 0px;
	border-radius: 5px;
	border: none;
	color: var(--cor-letra);
	outline: none;
	border: var(--lines) solid var(--filtro-lines);
	font-size: 1em;
	padding: 2px;
	background: white;
}

.autocomplete-fieldInput {
	display: none;
}

.form-control-inModal {
	margin-left: 0px;
	height: 30px;
	vertical-align: center;
	line-height: 200%;
	margin-top: 0px;
	border-radius: 5px;
	border: none;
	color: var(--cor-letra);
	outline: none;
	border: none;
	font-size: 1em;
	padding: 2px;
}

.labelModal {
	display: block;
	font-size: .9em;
	color: var(--modal-font);
	text-align: left;
	margin-top: 10px;
}

.labelModalNova {
	display: block;
	font-size: .9em;
	color: var(--modal-font);
	text-align: left;
	margin-top: 30px;
}

.labelModalR {
	display: block;
	font-size: .9em;
	color: var(--modal-font);
	text-align: right;
	margin-top: 5px;
}

input[type=radio] {
	margin-left: 10px;
	width: 20px;
	height: 15px;
	text-align: center;
	align-self: center;
	vertical-align: center;
}

input[type=checkbox] {
	margin-left: 10px;
	width: 20px;
	height: 15px;
	text-align: center;
	align-self: center;
	vertical-align: center;
}

input[type=text] {
	text-transform: uppercase;
}

textarea {
	text-transform: uppercase;
}

.notUpper {
	text-transform: none !important;
}

.buttonFiltroProcessar {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	width: 150px;
	margin-left: 10px;
	border: none;
	left: calc(100vw - 145px);
	padding: 3px;
	display: block;
}

.check {
	/* margin-top: px !important; */
	/* margin-bottom: 10px !important; */
	margin-left: 0px !important;
}

.buttonDetail {
	/* background-color: var(--button-background); */
	background-color: var(--buttons-detail-background);
	color: var(--buttons-detail-font);
	border-radius: 10px;
	vertical-align: center;
	text-align: center;
	width: 10%;
	margin-left: 10px;
	border: none;
	padding: 3px;
	z-index: 30;
}

.buttonDetailColumn {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	vertical-align: center;
	text-align: center;
	width: 40%;
	margin-left: 10px;
	border: none;
	padding: 3px;
	z-index: 30;
}

.buttonDetailColumn2 {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	vertical-align: center;
	text-align: center;
	width: 100%;
	border: none;
	padding: 3px;
	z-index: 30;
}

.checkModal {
	margin: 0 0 0 0;
	width: 100%;
	text-align: left;
}

.buttonNovo {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	margin-top: 5px;
	/* text-align: center; */
	width: 150px;
	margin-left: 10px;
	/* margin-bottom: 20px; */
	border: none;
	padding: 3px;
	display: block;
}

.buttonGrid {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	margin-top: 5px;
	width: 80px;
	margin-left: 1px;
	border: none;
	padding: 3px;
	/* display: block; */
}

.buttonExportacao {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	margin-top: 5px;
	/* text-align: center; */
	width: 150px;
	margin-left: 10px;
	/* margin-bottom: 20px; */
	border: none;
	padding: 3px;
	display: block;
	height: 26px;
}

.ListSelect {
	color: var(--modal-button-font);
	list-style-type: none;
	border-radius: 6px;
	padding-left: 0px;
	font-size: 1.3em;
	height: 160px;
	margin-bottom: 0;
	overflow-y: auto;
	outline:none;
	border-radius: 6px;
	max-width: 200px;
	border: 1px solid #ccc;
}



.rowColumns{
	flex-direction: row;
}

.containerColumns {
	max-width: 420px;
	flex-wrap: wrap;
	margin: 0 auto;
	display: flex;
}

.itemColumns {
	/* O flex: 1; é necessário para que cada item se expanda ocupando o tamanho máximo do container. */
	flex: 1;
	margin: 5px;
	width: 210px;
	/* background: tomato; */
	text-align: center;
	/* font-size: 1.5em; */
}

.labelColumns{
	text-align: left;
	/* background: red; */
	width: 100%;
}

.ListSelect li {
	border-radius: 6px;
	background-color: var(--modal-button-background);
	border: 1px var(--modal-button-font) solid;
}


.ListSelect li:hover{
	border-radius: 6px;
	background-color: var(--modal-button-background-hover);
	border: 1px var(--modal-button-font) solid;
}

.ListSelect li:active{
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.24), 0 5px 25px 0 rgba(0, 0, 0, 0.19);
	transform: translateY(1px);
}

.buttonSalvar {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	text-align: center;
	width: 100px;
	border: none;
	padding: 3px;
}

.buttonArquivo {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	text-align: center;
	width: 100px;
	border: none;
	padding: 3px;
	margin-left: 10px;
}

.buttonVoltar {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	text-align: center;
	margin-left: 30px;
	width: 100px;
	border: none;
	padding: 3px;
}

.buttonsDetail {
	margin-top: 10px;
	text-align: center;
	margin-bottom: 10px;
}

.buttonsDetailColumn {
	text-align: center;
}

.buttonFiltroLimpar {
	background-color: var(--button-background);
	color: var(--button-font);
	border-radius: 10px;
	border: none;
	margin-left: 10px;
	width: 150px;
	padding: 3px;
	margin-top: 5px;
	display: block;
}

.boxTabela {
	margin-left: 30px;
	margin-right: 30px;
	margin-top: 30px;
	margin-bottom: 30px;
	min-height: 100vh;
	display: block;
	z-index: 10;

	/* height: 50vh; */
	/* border: 2px dashed black */
}

.boxNavBar {
	height: 52px;
	width: auto;
	margin: 0;
}

.themeNavBar {
	color: var(--navBar-font);
	width: 100%;
	height: 30px;
	background-color: var(--navBar-background);
	border-bottom: 2px solid var(--navBar-border);
}

.secondItemNavBar {
	color: var(--navBar-detailed-font);
}

.secondItemNavBar:hover {
	color: white;
	text-decoration: none;
}

.themeDetailNavBar {
	background-color: var(--navBar-detailed-background);
	white-space: nowrap;
	padding: 5px 10px 10px 10px;
	border: 2px solid var(--navBar-border);
}

.firstItemNavBar {
	color: var(--navBar-font);
	width: 150px;
	height: 30px;
	text-align: center;
	text-align: center;
	vertical-align: middle;
	line-height: 200%;
	display: inline-block;
}

.firstItemNavBar:hover {
	color: white;
	text-decoration: none;
	background-color: var(--navBar-background-selected);
	border-bottom: 2px solid var(--navBar-border);
}

.firstItemNavBar:active {
	color: white;
	text-decoration: none;
	background-color: var(--navBar-background-selected);
}

.logOutNavBar {
	color: var(--navBar-font);
	position: absolute;
	right: 0px;
	width: 100px;
	height: 30px;
	text-align: center;
	vertical-align: middle;
	line-height: 200%;
	display: inline-block;
	border-left: 2px solid var(--navBar-border);
}

.logOutNavBar:hover {
	color: white;
	text-decoration: none;
	background-color: var(--navBar-background-selected);
	border-left: 2px solid var(--navBar-border);
	border-bottom: 2px solid var(--navBar-border);
}

.headItemNavBar {
	color: var(--navBar-font);
	width: 200px;
	height: 30px;
	text-align: center;
	vertical-align: middle;
	line-height: 200%;
	display: inline-block;
	border-right: 2px solid var(--navBar-border);
}

.headItemNavBar:hover {
	color: white;
	text-decoration: none;
	background-color: var(--navBar-background-selected);
	border-right: 2px solid var(--navBar-border);
	border-bottom: 2px solid var(--navBar-border);
}

.boxHeader {
	margin-top: 30px;
	margin-bottom: 30px;
	display: block;
}

.headerCadastro {
	text-align: center;
	padding: 5px;
	border-radius: 10px;
	background-color: var(--header-background);
	color: var(--header-font);
	margin-left: 30px;
	margin-right: 30px;
}

.headerModal {
	text-align: center;
	border-radius: 10px;
	font-weight: bold;
	margin-top: 20px;
	background-color: var(--modal-header-background);
	color: var(--modal-header-font);
}

button:hover {
	box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
}

button:active {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24), 0 6px 25px 0 rgba(0, 0, 0, 0.19);
	transform: translateY(2px);
}

.iconButton {
	background-color: var(--button-background);
	color: var(--button-font);
	vertical-align: center;
	text-align: center;
	border: none;
	padding: 2px;
	z-index: 30;
	border-radius: 6px;
	margin-right: 5px;
}

.iconButtonRed {
	background-color: red;
	color: var(--button-font);
	vertical-align: center;
	text-align: center;
	border: none;
	padding: 2px;
	z-index: 30;
	border-radius: 6px;
	margin-right: 5px;
}



.newVersion {
	background-color: var(--button-background);
	color: var(--button-font);
	border: none;
	border-radius: 15px;
	margin-left: 5px;
}

.iconButton:hover {
	box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.24), 0 8px 25px 0 rgba(0, 0, 0, 0.19);
}

.iconButton:active {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.24), 0 6px 25px 0 rgba(0, 0, 0, 0.19);
	transform: translateY(1px);
}

.column-filter {
	float: left;
	width: 80%;
}


.column-modal {
	float: center;
	width: 80%;
	margin-top: -25px;
}

.column-autocomplete {
	width: 80%;
}

@media only screen and (min-width: 900px) {
	.column-filter-2 {
		margin-top: -15px;
		margin-right: 5px;
		float: right;
		text-align: center;
	}
}

@media only screen and (max-width: 900px) {
	.column-filter-2 {
		margin-top: -15px;
		float: center;
		text-align: center;
	}
}

.inputLower {
	text-transform: lowercase !important;
}

.divCancelado {
	background: red;
}


.multi-select .dropdown .dropdown-heading {
	border: 2px solid #01352c !important;
	height: 30px !important;
}

.multi-select .dropdown .dropdown-heading .dropdown-heading-value {
	line-height: 30px !important;
}

.dropdown-menu {
	max-height: 80vh;
	overflow-y: auto;
}